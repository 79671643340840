import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news3pic1 from '../assets/images/news3.jpg'
import banCiao3 from '../assets/images/IMG_7883.jpg'
import banCiao7 from '../assets/images/IMG_7887.jpg'
import googleMap from '../assets/images/google-maps.png'

const News3 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 板橋店學生暑期方案</title>
      <meta name="description" content="三健客板橋店學生暑期方案" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>板橋店學生暑期方案</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news3pic1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={banCiao3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={banCiao7} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h2>板橋店推出 ＃學生優惠方案</h2>
          <h4>【 掀 起 盛 夏 健 狂 潮 🏋🏼‍♀️ 】</h4>
          <p>
            這個暑假，學生福氣拉！ 人魚線、川字肌通通秀出來！ 夏日不秀何時秀🔥🔥
          </p>

          <p>
            <h4>優惠時間：6/15-8/15</h4>
            <h4>優惠場館：三健客板橋店</h4>
            憑學生證即可享有此優惠方案喔！（一定要是學生喔XD)
            <br />
            ⚠️注意事項：
            <br />
            📣三健客保留一切活動變更的權利
            <br />
            📣本活動不得與其他優惠併用
          </p>
          <p>
            <h4>場館資訊：</h4>
            地址：
            <a
              href="https://goo.gl/maps/2NJbg4ox6gW3BQR78"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              新北市板橋區莒光路211號b1
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
            <br />
            電話：02 8258 8025
            <br />
            營業時間：上午7:00 - 下午11:00
          </p>
          <h4>
            有興趣想報名的請私訊三健客板橋店粉絲專頁
            <a
              href="https://www.facebook.com/%E4%B8%89%E5%81%A5%E5%AE%A2-3musclers-%E6%9D%BF%E6%A9%8B%E5%BA%97-125554492170293/"
              className="icon alt fa-facebook"
            >
              <span className="label">Facebook</span>
            </a>
          </h4>
        </div>
      </section>
    </div>
  </Layout>
)

export default News3
